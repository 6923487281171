<template>
  <div class="min-h-screen">
    <div
      class="w-full lg:w-2/6 mx-auto relative bg-gray-50 m-4 p-8 rounded-md border shadow-lg"
    >
      <form
        action=""
        @submit.prevent="NewCourse()"
        class="w-full lg:p-4 mx-auto"
      >
        <h1 class="text-left py-2 text-3xl font-semibold text-gray-600">
           Course with AI
        </h1>
        <!-- Step 1 -->
        <div v-if="currentStep === 1" class="flex flex-col gap-2">
          <p>Step 1</p>
          <div class="">
            <label for="category" class="flex gap-2 items-center">
              <div>Category<span class="text-red-400">*</span></div>
            </label>
            <select
              name="category"
              v-model="form.category" required
              class="p-2 block w-full rounded-md text-sm"
              id="category"
            >
              <option value="MBA">MBA</option>
              <option value="MCA">MCA</option>
              <option value="BCA">BCA</option>
              <option value="BBC">BBC</option>
            </select>
          </div>
          <div class="">
            <label for="batch">
              Batch<span class="text-red-400">*</span>
            </label>
            <input
              v-model="form.batch" required
              id="batch"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div class="">
            <label for="code"> Code<span class="text-red-400">*</span> </label>
            <input
              v-model="form.code" required
              id="code"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div class="flex justify-end">
            <button class="gradient-btn mt-4" @click="nextPage">Next</button>
          </div>
        </div>

        <!-- Step 2 -->
        <div v-if="currentStep === 2" class="flex flex-col gap-2">
          <p>Step 2</p>
          <div>
            <label for="domain"
              >Domain<span class="text-red-400">*</span></label
            >
            <input
              v-model="form.domain" required
              id="domain"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div>
            <label for="title">Title<span class="text-red-400">*</span></label>
            <input
              v-model="form.title" required
              id="title"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div class="flex justify-between">
            <button type="button" class="gradient-btn mt-4" @click="prevPage">
              Previous
            </button>
            <button type="button" class="gradient-btn mt-4" @click="nextPage">
              Next
            </button>
          </div>
        </div>

        <!-- Step 3 -->
        <div v-if="currentStep === 3" class="flex flex-col gap-2">
          <p>Step 3</p>
          <div>
            <label for="units">Units<span class="text-red-400">*</span></label>
            <input
              v-model="form.units" required
              id="units"
              type="number"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div>
            <label for="size"
              >Unit size<span class="text-red-400">*</span></label
            >
            <input
              v-model="form.size" required
              id="size"
              type="number"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>

          <div class="flex justify-start items-center gap-2">
            <label for="freeCourse"> Assessments </label>
            <input
              id="Assessments"
              type="checkbox"
              class=""
              required
              @change="toggleSub"
            />
          </div>

          <div v-if="isAssessmentsActive">
            <label for="assessmentDetails">How many quiz</label>
            <input
              v-model="form.QuizData" required
              id="QuizData"
              type="number"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              placeholder=""
            />
          </div>

          <div>
            <label for="instruction"
              >Instruction<span class="text-red-400">*</span></label
            >

            <textarea
              name=""
              v-model="form.instruction"
              id=""
              cols="20"
              rows="5"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            >
            </textarea>
          </div>
          <div class="flex justify-between">
            <button type="button" class="gradient-btn mt-4" @click="prevPage">
              Previous
            </button>
            <button type="submit" class="gradient-btn mt-4">Submit</button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="popup" class="fixed top-0 left-0 z-50 bg-black bg-opacity-60 h-screen w-full flex items-center">
    <div class="relative w-auto mx-auto rounded-lg bg-white p-12 text-gray-700 text-center shadow-xl">
        <div @click="popup=false" class="absolute right-4 top-0 text-6xl text-right font-light primary-text-color cursor-lg cursor-pointer">&times;</div>
        
        <h2 class="p-4 text-lg font-semibold">Thank you update your instructions</h2>
         <button type="submit" class="gradient-btn mt-4" @click="generate()">Generate</button>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewCourse",
  data() {
    return {
      currentStep: 1,
      form: {
        category: "",
        batch: "",
        code: "",
        domain: "",
        title: "",
        units: ",",
        size: "",

        instruction: "",
        QuizData: "",
      },
      isAssessmentsActive: false,
      popup:false,
    };
  },

  methods: {
    nextPage() {
      if (this.currentStep < 3) {
        this.currentStep++;
      }
    },
    prevPage() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    toggleSub(event) {
      this.isAssessmentsActive = event.target.checked;
    },
    NewCourse() {
      console.log("form", this.form);
      // this.$router.push({ name: "NewCoursepreview" });
      this.popup=true
    },
    generate(){
      this.$router.push({ name: "NewCoursepreview" });
    }
  },
};
</script>

<style></style>
