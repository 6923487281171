<template>
  <div class="min-h-screen">
    <div
      class="w-11/12 2xl:w-4/6 mx-auto relative bg-gray-50 m-4 p-8 rounded-md border shadow-lg"
    >
      <h1 class="text-left py-2 text-3xl font-semibold text-gray-600">
        Preview
      </h1>
      <div class="flex flex-col gap-8">
        <!-- step1 -->
        <div>
          <p>Step 1</p>
          <div class="">
            <label for="category" class="flex gap-2 items-center">
              <div>Category<span class="text-red-400">*</span></div>
            </label>
            <input
              id="category"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              readonly
             
            />
          </div>

          <div class="">
            <label for="batch">
              Batch<span class="text-red-400">*</span>
            </label>
            <input
              id="batch"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              readonly
            />
          </div>
          <div class="">
            <label for="code"> Code<span class="text-red-400">*</span> </label>
            <input
              id="code"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              readonly
            />
          </div>
        </div>
        <!-- step2 -->
        <div>
          <p>Step 2</p>
          <div>
            <label for="domain"
              >Domain<span class="text-red-400">*</span></label
            >
            <input
              id="domain"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              readonly
            />
          </div>
          <div>
            <label for="title">Title<span class="text-red-400">*</span></label>
            <input
              id="title"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              readonly
            />
          </div>
        </div>
        <!-- step3 -->
        <div>
          <p>Step 3</p>
          <div>
            <label for="units">Units<span class="text-red-400">*</span></label>
            <input
              id="units"
              type="number"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              readonly
            />
          </div>
          <div>
            <label for="size"
              >Unit size<span class="text-red-400">*</span></label
            >
            <input
              id="size"
              type="number"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              readonly
            />
          </div>
          <div>
            <label for="assessmentDetails"
              >How many quiz<span class="text-red-400">*</span></label
            >
            <input
              id="assessmentDetails"
              type="number"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              readonly
              placeholder=""
            />
          </div>
          <div>
            <label for="instruction"
              >Instruction<span class="text-red-400">*</span></label
            >

            <input
              id="instruction"
              type="Text"
              class="border border-gray-300 rounded-l-md text-sm w-full p-2"
              readonly
            />
          </div>
        </div>

        <div class="flex justify-between">
          <button type="button" class="gradient-btn mt-4" @click=" prevPage()">Previous</button>
          <!-- <button type="button" class="gradient-btn mt-4">Generate</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewCoursePreview",
  data() {
    return {
      
    };
  },
  methods:{
     prevPage(){
        this.$router.push({ name: "NewCourse" });
     }
  }
};
</script>

<style></style>
